<template>
    <main>

        <hero>
            <h4>
                {{ siteName }}
            </h4>

            <h1>
                <span>
                    We beschikken over
                </span>

                <span>
                    een uitgebreide werkplaats
                </span>
            </h1>
        </hero>
        

        <section>
            <div class="cell">
                <p>
                    U kunt bij ons terecht voor het reguliere onderhoud, storingen, banden, het laten monteren van accessoires of het stellen van diagnose aan uw motorfiets.
                    Wij beschikken over het nieuwste TEXA Diagnose systeem waarbij we van alle merken storing kunnen uitlezen en de  service intervallen kunnen resetten.
                    Bij ons is de werkplaats vrij toegankelijk, ben je vrij om mee te kijken onder het genot van een bakje koffie.
                    Wij zijn RDW erkend, dat betekent dat de km-stand ook netjes geregistreerd wordt volgens de nieuwe regelgeving betreffende de km-registratie motorfietsen. 
                </p>
            </div>
        </section>

        <contact />

    </main>
</template>


<script>
import { mapGetters } from 'vuex';

import Contact from '@/components/Contact.vue';
import Hero from '@/components/Hero.vue';

export default {
    components: {
        Contact,
        Hero,
    },


    computed: {
        ...mapGetters([
            'siteName',
        ]),
    },
}
</script>