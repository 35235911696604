<template>
    <main>

        <hero>
            <h4>
                {{ siteName }}
            </h4>

            <h1>
                <span>
                    We kijken verder
                </span>

                <span>
                    dan alleen de banden
                </span>
            </h1>
        </hero>


        <section class="usps">
            <div class="cell">
                <h2>
                    {{ siteName }} staat voor
                </h2>
                
                <ul>
                    <li>
                        Goede service, waarbij we verder kijken dan alleen de banden
                    </li>
                    <li>
                        Alle merken en types leverbaar voor elk type motorfiets
                    </li>
                    <li>
                        Hulp bij uw keuze voor de juiste banden voor u
                    </li>
                    <li>
                        's Avonds en in het weekend afspraak mogelijk
                    </li>
                </ul>
            </div>
        </section>


        <section class="about">
            <div class="cell">
                <h2>
                    Over {{ siteName }}
                </h2>
                
                <p>
                    Als klein eenmansbedrijf in de motorfiets branche bieden wij kleinschaligheid, waardoor wij dicht bij de klanten staan en daardoor een goede service kunnen geven.
                    Door de samenwerking met grote leveranciers kunnen wij een breed assortiment tegen interessante voorwaarden leveren.
                    Zonder personeel en zonder dure showroom: dat geld kunnen onze klanten in hun zak houden.
                </p>

                <p>
                    Wij werken alleen op afspraak, een afspraak kan bij ons 7 dagen per week gemaakt worden, dus u bent ook welkom in het weekend en/of de avond uren.
                </p>
            </div>
        </section>


        <contact />

    </main>
</template>


<script>
import { mapGetters } from 'vuex';

import Contact from '@/components/Contact.vue';
import Hero from '@/components/Hero.vue';

export default {
    components: {
        Contact,
        Hero,
    },


    computed: {
        ...mapGetters([
            'siteName',
        ]),
    },
}
</script>