import { createStore } from "vuex";

// import axios from 'axios';

var base = 'https://motorbandenzeeland.nl/';

export default createStore({
    state: {
		base: base,
		// duration: 390,
		// pages: [],
		siteName: 'Motorbanden & Service Zeeland',
		// structure: [],
	},


	getters: {
		base: state => {
			return state.base;
		},


		// duration: state => {
		// 	return state.duration;
		// },


		// pages: state => {
		// 	return state.pages;
		// },


		siteName: state => {
			return state.siteName;
		},


		// structure: state => {
		// 	return state.structure;
		// },
	},


	// mutations: {
	// 	setPages (state, value)
	// 	{
	// 		state.pages = value;
	// 	},


	// 	setStructure (state, value)
	// 	{
	// 		state.structure = value;
	// 	},
	// },


	// actions: {
	// 	initialize: (context, payload) => {
	// 		return axios.get(base + 'api/initialize/', { params: payload })
	// 		.then(response => {
	// 			context.commit('setPages', response.data.pages);
	// 			context.commit('setStructure', response.data.structure);
	// 		})
	// 		.catch(function (error)
	// 		{
	// 			console.log(error);
	// 		});
	// 	},
	// },
});
