<template>
	<section class="contact" id="contact">

		<div class="cell reveal from-bottom">
			<h2>
				Neem contact op
			</h2>

			<p>
				Heeft u nog vragen? Maak gebruik van het contactformulier of neem direct contact op.
				Dit kan telefonisch
				<span>
					(06 461 88 677)
				</span>
				of via de mail
				<span>
					(info@motorbandenzeeland.nl).
				</span>
			</p>

			<p>
				<a href="mailto:info@motorbandenzeeland.nl" class="button">
					<span>
						Stuur een mail
					</span>

					<i class="fas fa-envelope"></i>
				</a>

				<a href="tel:0031646188677" class="button">
					<span>
						Bel ons
					</span>

					<i class="fas fa-phone"></i>
				</a>
			</p>
		</div>


		<transition name="fade" mode="out-in">

			<loader v-if="processing" />

		</transition>


		<div v-if="success !== null" class="cell reveal from-bottom">
            
            <div v-if="success === false" class="feedback error">
                <p>
                    Helaas is met verzenden van uw bericht mislukt.
                </p>

                <p>
                    Probeer het nogmaals, of mail direct naar

                    <a href="mailto:info@motorbandenzeeland.nl">
                        info@motorbandenzeeland.nl
                    </a>
                </p>
            </div>

            <p v-if="success">
                Bedankt voor uw bericht. Er wordt z.s.m. op gereageerd.
            </p>

		</div>


		<form v-else class="cell">
			<h2>
				Stel uw vraag
			</h2>
			
			<ul>
				<li :class="contact.name.length > 0 ? 'filled' : ''">
					<input type="text" placeholder="Naam..." v-model="contact.name" />
				</li>

				<li :class="validEmail ? 'filled' : ''">
					<input type="email" placeholder="E-mail..." v-model="contact.email" />
				</li>

				<li :class="contact.message.length > 0 ? 'filled' : ''">
					<textarea placeholder="Bericht..." v-model="contact.message" />
				</li>

				<li>
					<a href="#" class="button" @click.prevent="submit" :class="!validForm ? 'disabled' : ''">
						<span>
							Versturen
						</span>

						<i class="fas fa-paper-plane"></i>
					</a>
				</li>
			</ul>
		</form>

	</section>
</template>


<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import Loader from '@/components/Loader.vue';

var vm;

export default {
	components: {
		Loader,
	},


	data()
	{
		return {
			contact: {
				name: '',
				email: '',
				message: '',
			},
            processed: false,
			submitted: false,
			success: null,
		};
	},


	computed: {
		...mapGetters([
			'base',
		]),


		validEmail()
		{
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(vm.contact.email.toLowerCase());
		},
		

		validForm()
		{
			let keys = Object.keys(vm.contact);
			
			for (let i = 0; i < keys.length; i++)
			{
				let k = keys[i];

				if (vm.contact[k].length === 0) return false;
			}

			if (!vm.validEmail) return false;

			return true;
		}
	},


	methods: {
        async submit()
		{
			vm.submitted = true;

            let formData = new FormData();

            // for (let i = 0; i < vm.files.length; i++)
            // {
            // 	let f = vm.files[i];
            //
            // 	formData.append('files[' + i + ']', f);
            // }

            formData.append('action', 'contact');
            formData.append('data', JSON.stringify(vm.contact));

			if (process.env.NODE_ENV == 'development')
			{
				setTimeout(() => {
					vm.success = true;
					vm.processed = true;
				}, 3000);
			}
			else
			{
				await axios.post(vm.base + 'api/forms/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					vm.processed = true;
					vm.success = response.data;
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},
    },


	created()
	{
		vm = this;
	},
}
</script>