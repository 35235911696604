<template>

    <header>
        <router-link :to="{ name: 'Home' }" class="logo">
            <img src="img/logo.svg" alt="Motorband Zeeland &amp; Service" />
        </router-link>

        <a href="#" class="menu-toggle" @click.prevent="showNav = !showNav">
            Menu

            <i class="fas fa-bars"></i>
        </a>

        <nav :class="navState">
            <router-link to="/" @click="showNav = false">
                Motorbanden
            </router-link>
            
            <router-link :to="{ name: 'Service' }" @click="showNav = false">
                Service
            </router-link>

            <a href="http://nagtegaalmotorkeuringen.nl" target="_blank">
                Keuringen
            </a>

            <a href="#contact" @click.prevent="slideTo">
                Contact
            </a>
        </nav>
    </header>

</template>


<script>
var vm;

export default {
    data()
    {
        return {
            showNav: false,
        };
    },


    computed: {
        navState()
        {
            return vm.showNav ? 'open' : '';
        },
    },
    

	methods: {
		slideTo(e)
        {
            let target = e.target;

            while (target.tagName.toLowerCase() != 'a')
            {
                target = target.parentNode;
            }

            let el = document.querySelector(target.attributes.href.value),
                header = document.querySelector('header'),
                y = header.offsetTop == 0 ? el.offsetTop - header.clientHeight : el.offsetTop;

            // Smooth scroll to element and align it at the bottom
            window.scrollTo({top: y, behavior: 'smooth'});

            // Hide the menu on mobile
            vm.showNav = false;
        },
	},

    
    created()
    {
        vm = this;
    },
}
</script>