<template>
    
    <footer>
        
        <div class="cell">
            <h3>
                Bezoekadres
            </h3>

            <p>
                Noordweegse weg 16-07
                <br />
                4458 AT 's-Heer Arendskerke
                <br />
                Industrieterrein Eindewege,
                Zijkant bedrijfsverzamelgebouw
            </p>
        </div>

        

        <div class="cell">
            <h3>
                Postadres
            </h3>

            <p>
                Middelgatstraat 7
                <br />
                4456 BM Lewedorp
            </p>
        </div>


        <div class="cell">
            <h3>
                Online
            </h3>

            <div>
                <i class="fab fa-instagram"></i>

                <a href="https://www.instagram.com/motorbandenzeeland" target="_blank">
                    @motorbandenzeeland
                </a>
            </div>

            <div>
                <i class="fab fa-facebook-f"></i>

                <a href="https://www.facebook.com/motorbandenservicezeeland" target="_blank">
                    @motorbandenservicezeeland
                </a>
            </div>

            <div>
                <i class="fab fa-twitter"></i>

                <a href="https://twitter.com/motorzeeland" target="_blank">
                    @motorzeeland
                </a>
            </div>

        </div>

    </footer>


    <footer class="credits">
        
        <span>
            &copy; {{ year }} {{ siteName }}
        </span>

        <span>
            Realisatie:

            <a href="https://dizero.nl" target="_blank">
                Dizero
            </a>
        </span>

    </footer>

</template>


<script>
import { mapGetters } from 'vuex';

export default {
    data()
    {
        return {
            year: new Date().getFullYear(),
        };
    },


    computed: {
        ...mapGetters([
            'siteName',
        ]),
    },
}
</script>