<template>
    <article>

        <app-header />

        
        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component" :key="$route.path" />
            </transition>
        </router-view>

        
        <app-footer />
	
    </article>
</template>


<script>
import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';


export default {
    components: {
        AppFooter,
        AppHeader,
    },
}
</script>


<style lang="scss">

    @import "assets/css/all.min.css";
    @import "assets/scss/app.scss";

</style>