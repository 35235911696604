<template>
    <section class="banner">
        <div class="cell">
            <slot />
        </div>

        <div class="hint">
            Scroll verder
        </div>
    </section>
</template>